import React from 'react';
import styles from './style.module.scss';
import { useGoogleLogin } from '@react-oauth/google';
import { userService } from '@/services/userService';
import { useDispatch } from 'react-redux';
import { hideLoginModal, resetLoginModal } from '@/store/features/loginSlice';
import WsStaticImage from '@/components/common/WsStaticImage';
import { GoogleOAuthProvider } from '@react-oauth/google';
// interface Props {
//   // Define the props for your component here
// }
// const Social: React.FC<Props> = (
//   {
//     /* Destructure props here */
//   }
// ) => {
//   const dispatch = useDispatch();
//   const login = useGoogleLogin({
//     flow: 'auth-code',
//     onSuccess: (codeResponse) => {
//       userService
//         .loginWithSocial({
//           code: codeResponse.code,
//           type: 'google',
//         })
//         .then((res) => {
//           if (res?.code == 200) {
//             dispatch(resetLoginModal());
//             dispatch(hideLoginModal());
//           }
//         });
//     },
//     onError: (errorResponse) => console.log(errorResponse),
//   });

//   useEffect(() => {
//     const loadFacebookSDK = () => {
//       if (typeof window !== 'undefined' && !(window as any).FB) {
//         // Load the Facebook SDK
//         const script = document.createElement('script');
//         script.src = 'https://connect.facebook.net/en_US/sdk.js';
//         script.async = true;
//         script.defer = true;
//         script.onload = () => {
//           // Initialize the SDK
//           (window as any).FB.init({
//             appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
//             autoLogAppEvents: true,
//             xfbml: true,
//             version: 'v12.0',
//           });
//         };
//         document.body.appendChild(script);
//       }
//     };

//     loadFacebookSDK();
//   }, []);

//   const handleLogin = () => {
//     if (typeof window !== 'undefined' && (window as any).FB) {
//       (window as any).FB.getLoginStatus((response: any) => {
//         if (response.status === 'connected') {
//           console.log('User is already logged in', response);
//         } else {
//           (window as any).FB.login(
//             (response: any) => {
//               if (response.authResponse) {
//                 const { accessToken } = response.authResponse;
//                 userService.loginWithSocial({
//                   code: accessToken,
//                   type: 'facebook',
//                 });
//                 dispatch(resetLoginModal());
//                 dispatch(hideLoginModal());
//               } else {
//                 console.error('User cancelled login or failed.');
//               }
//             },
//             { scope: 'public_profile,email' }
//           );
//         }
//       });
//     }
//   };
//   return (
//     <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_ID as string}>
//       <div className={styles['social-wrap']} id="social_login_wrap">
//         <p>OR Continue With</p>
//         <a
//           className={styles['btn-facebook']}
//           id="google_plus_login"
//           aria-label="google"
//           onClick={(e) => {
//             e.preventDefault(), login();
//           }}
//         >
//           <WsStaticImage
//             src={'/images/login/googleplus.svg'}
//             alt="Home Temple"
//             width={24}
//             height={24}
//           />
//           <span>GOOGLE</span>
//         </a>
//         <a
//           className={`${styles['btn-facebook']} ${styles['mr-0']}`}
//           target="_self"
//           aria-label="facebook"
//           onClick={(e) => {
//             e.preventDefault(), handleLogin();
//           }}
//         >
//           <WsStaticImage
//             src={'/images/login/facebook.svg'}
//             alt="Home Temple"
//             width={24}
//             height={24}
//           />
//           <span>FACEBOOK</span>
//         </a>
//       </div>
//     </GoogleOAuthProvider>
//   );
// };

// export default Social;

const Social = () => {
  const dispatch = useDispatch();

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_ID as string}>
      <div className={styles['social-wrap']} id="social_login_wrap">
        <p>OR Continue With</p>

        <GoogleLoginButton dispatch={dispatch} />

        <a
          className={`${styles['btn-facebook']} ${styles['mr-0']}`}
          target="_self"
          aria-label="facebook"
          onClick={(e) => {
            e.preventDefault();
            handleFacebookLogin(dispatch);
          }}
        >
          <WsStaticImage
            src={'/images/login/facebook.svg'}
            alt="Home Temple"
            width={24}
            height={24}
          />
          <span>FACEBOOK</span>
        </a>
      </div>
    </GoogleOAuthProvider>
  );
};

const GoogleLoginButton: React.FC<{ dispatch: any }> = ({ dispatch }) => {
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (codeResponse) => {
      userService
        .loginWithSocial({
          code: codeResponse.code,
          type: 'google',
        })
        .then((res) => {
          if (res?.code === 200) {
            dispatch(resetLoginModal());
            dispatch(hideLoginModal());
          }
        });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <a
      className={styles['btn-facebook']}
      id="google_plus_login"
      aria-label="google"
      onClick={(e) => {
        e.preventDefault();
        login();
      }}
    >
      <WsStaticImage
        src={'/images/login/googleplus.svg'}
        alt="Home Temple"
        width={24}
        height={24}
      />
      <span>GOOGLE</span>
    </a>
  );
};

const handleFacebookLogin = (dispatch: any) => {
  if (typeof window !== 'undefined' && (window as any).FB) {
    (window as any).FB.getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        console.log('User is already logged in', response);
      } else {
        (window as any).FB.login(
          (response: any) => {
            if (response.authResponse) {
              const { accessToken } = response.authResponse;
              userService.loginWithSocial({
                code: accessToken,
                type: 'facebook',
              });
              dispatch(resetLoginModal());
              dispatch(hideLoginModal());
            } else {
              console.error('User cancelled login or failed.');
            }
          },
          { scope: 'public_profile,email' }
        );
      }
    });
  }
};

export default Social;
